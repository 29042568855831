import React from 'react';
import {Link, Route, Routes, useLocation} from 'react-router-dom';
import About from './page/About';
import Contact from './page/Contact';
import Services from './page/Services';
import NotFound from './page/NotFound';

function App() {
  const location = useLocation();
  const pathName = location.pathname;

  return (
    <div className={`site ${pathName === '/' ? 'full-header-page' : 'small-header-page'}`}>
      <header className="site-header">
        <div className="custom-header">
          <div className="custom-header-media">
            <div id="wp-custom-header" className="wp-custom-header">
              <img
                src="assets/uploads/2017/11/cropped-austin-eye-doctor-spl-4.jpg" width="2000" height="1200"
                alt=""
                srcSet="assets/uploads/2017/11/cropped-austin-eye-doctor-spl-4.jpg 2000w, assets/uploads/2017/11/cropped-austin-eye-doctor-spl-4-300x180.jpg 300w, assets/uploads/2017/11/cropped-austin-eye-doctor-spl-4-768x461.jpg 768w, assets/uploads/2017/11/cropped-austin-eye-doctor-spl-4-1024x614.jpg 1024w"
                sizes="100vw" decoding="async"/>
            </div>
          </div>
          <div className="site-branding" style={{marginBottom: '72px'}}>
            <div className="wrap">
              <div className="site-branding-text">
                <h1 className="site-title"><a href="/" rel="home">Hau Eyecare</a></h1>
                <p className="site-description">Your Austin Eye Doctor</p>
              </div>
            </div>
          </div>
        </div>
        <div className="navigation-top">
          <div className="wrap">
            <nav id="site-navigation" className="main-navigation" role="navigation" aria-label="Top Menu">
              <button className="menu-toggle" aria-controls="top-menu" aria-expanded="false">
                <svg className="icon icon-bars" aria-hidden="true" role="img">
                  <use href="#icon-bars"></use>
                </svg>
                <svg className="icon icon-close" aria-hidden="true" role="img">
                  <use href="#icon-close"></use>
                </svg>
                Menu
              </button>

              <div className="menu-main-container">
                <ul id="top-menu" className="menu">
                  <li id="menu-item-16"
                      className={`menu-item menu-item-type-post_type menu-item-object-page ${pathName === '/eye-care-services' ? 'current-menu-item page_item current_page_item' : ''}`}>
                    <Link to="/eye-care-services">Eye Care Services</Link>
                  </li>
                  <li id="menu-item-17"
                      className={`menu-item menu-item-type-post_type menu-item-object-page ${pathName === '/' ? 'current-menu-item page_item current_page_item' : ''}`}>
                    <Link to="/">About Hau Eyecare</Link>
                  </li>
                  <li id="menu-item-15"
                      className={`menu-item menu-item-type-post_type menu-item-object-page ${pathName === '/contact-austin-eye-doctor' ? 'current-menu-item page_item current_page_item' : ''}`}>
                    <Link to="/contact-austin-eye-doctor">Contact Your Austin Eye Doctor</Link>
                  </li>
                  <li className="menu-item menu-item-type-post_type menu-item-object-page">
                    <a className="phone" href="tel:+1512-343-7000">+1 (512) 343-7000</a>
                  </li>
                </ul>
              </div>
              <a href="#content" className="menu-scroll-down">
                {/*<span className="material-symbols-rounded">arrow_downward</span>*/}
                <svg className="icon icon-arrow-right" xmlns="http://www.w3.org/2000/svg" width="18px" height="24px"
                     viewBox="0 0 18 24">
                  <g id="surface1">
                    <path
                      d="M 7.941406 22.058594 C 8.527344 22.644531 9.476562 22.644531 10.0625 22.058594 L 17.5625 14.558594 C 18.148438 13.972656 18.148438 13.023438 17.5625 12.4375 C 16.976562 11.851562 16.027344 11.851562 15.441406 12.4375 L 10.5 17.382812 L 10.5 3 C 10.5 2.171875 9.828125 1.5 9 1.5 C 8.171875 1.5 7.5 2.171875 7.5 3 L 7.5 17.375 L 2.558594 12.441406 C 1.972656 11.855469 1.023438 11.855469 0.4375 12.441406 C -0.148438 13.027344 -0.148438 13.976562 0.4375 14.5625 L 7.9375 22.0625 Z M 7.941406 22.058594 "/>
                  </g>
                </svg>
                <span className="screen-reader-text">Scroll down to content</span></a>
            </nav>
          </div>
        </div>
      </header>

      <div id="content" className="site-content-contain">
        <div className="site-content container max-w-screen-md">
          <div className="px-8">
            <Routes>
              <Route path="/" element={<About/>}/>
              <Route path="/contact-austin-eye-doctor" element={<Contact/>}/>
              <Route path="/eye-care-services" element={<Services/>}/>
              <Route path="*" element={<NotFound/>}/>
            </Routes>
          </div>
        </div>

        <footer className="site-footer">
          <div className="wrap container PX-8">
            <aside className="widget-area">
              <div className="widget-column">
                <section className="widget_text widget_custom_html">
                  <h2 className="widget-title">Testimonials</h2>
                  <div className="textwidget custom-html-widget">
                    <p>
                      "Dr. Hau is the sweetest ever! She's kind, thorough, and accommodating." - Mia B.
                    </p>
                    <p>
                      "My insurance made me go to another eyedoctor but when I lost that insurance
                      I went right back to Dr Hau." - Teresa C.
                    </p>
                    <p>
                      "Excellent. The doctor and staff are both very knowledgable, professional and courteous.
                      One of the best experiences I've had." - Matthew H.
                    </p>
                    <p>
                      <a href="https://www.yelp.com/biz/hau-eye-care-austin" target="_blank" rel="noopener noreferrer">
                        <strong>See all our reviews on Yelp!</strong>
                      </a>
                    </p>
                  </div>
                </section>
              </div>
            </aside>
            <div className="site-info">
              <p>
                Contact me via phone number <a href="tel:+1512-343-7000">+1 (512) 343-7000</a> or email <a href="mailto:haueye2005@hushmail.com">haueye2005@hushmail.com</a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
