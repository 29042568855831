function About() {
  return (
    <div className="About">
      <header className="entry-header">
        <h2 className="entry-title">About Hau Eyecare</h2>
      </header>
      <div className="entry-content">
        <h3>Your Austin Eye Doctor</h3>
        <p>Welcome to Our Practice!</p>
        <p>Hau Eyecare is locally owned and operated since 2005. We are conveniently <a
          href="https://maps.google.com/?q=9700+North+Capital+of+TX+Hwy,+Ste.+A,+Austin,+TX+78759&amp;entry=gmail&amp;source=g"
          target="_blank" rel="noopener noreferrer">located next to Arboretum Sam&#8217;s Club #6453</a>. New patients
          are currently being accepted and a Sam&#8217;s Club membership is not required.</p>
        <p>At Hau Eyecare, we believe in treating every patient as if you are our family, treating you with
          respect and personal attention while providing the best for the health of your eyes. It is our
          privilege to serve you and meet your eye care needs.</p>
        <h3>Meet Your Eye Doctor</h3>
        <p>
          <a href="assets/uploads/2017/11/austin-eye-doctor.jpg">
            <img decoding="async"
                 className="alignright size-medium wp-image-18"
                 src="assets/uploads/2017/11/austin-eye-doctor-265x300.jpg"
                 alt="" width="265" height="300"
                 srcSet="assets/uploads/2017/11/austin-eye-doctor-265x300.jpg 265w, assets/uploads/2017/11/austin-eye-doctor-768x869.jpg 768w, assets/uploads/2017/11/austin-eye-doctor-906x1024.jpg 906w, assets/uploads/2017/11/austin-eye-doctor.jpg 1811w"
                 sizes="(max-width: 265px) 100vw, 265px"/>
          </a>
          Dr. Hau earned her Bachelor of Science degree in Biology at Southeastern Louisiana University in
          Hammond, LA and her Doctor of Optometry degree at Southern College of Optometry in Memphis, TN. She&#8217;s
          been practicing optometry for over 19 years. She completed her externship in primary care, contact
          lens and ocular disease. She also passed competency examination presented by the National Board of
          Examiners in Optometry in the treatment and management of eye disease.</p>
        <p>She received the Logo Paris&#8217; &#8221; Excellence in Eyesight&#8221; Award and New Orleans
          Contact Lens Society Scholarship. Dr. Hau is currently licensed by the Texas Optometry Board as a
          Therapeutic Optometrist. As an optometry student, Dr. Hau served as president of Gamma Omicron, an
          optometric service organization. She believes that giving back to the community that she serves is a
          great honor.</p>
        <h3>Our Location</h3>
        <p>
          <iframe style={{border: 0, width: '100%'}} title="Our Location Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3441.562931762597!2d-97.74496658487665!3d30.39176658175732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644cc800be7f397%3A0x6c4e97962773715e!2s9700+N+Capital+of+Texas+Hwy+a%2C+Austin%2C+TX+78759!5e0!3m2!1sen!2sus!4v1511682562753"
                  width="704" height="504" allowFullScreen loading="lazy">
          </iframe>
        </p>
      </div>
    </div>
  );
}

export default About;
