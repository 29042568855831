function Services() {
  return (
    <div className="Services">
      <header className="entry-header">
        <h2 className="entry-title">Eye Care Services</h2>
      </header>
      <div className="entry-content">
        <h5>Your Austin Eye Doctor</h5>
        <h3>Vision Care Services</h3>
        <div>Eyeglass examinations include an eye health exam of the interior and exterior of the eyes,
          assessment of eye teaming and coordination, testing for near, far and side vision, and testing for
          color vision and depth perception (3-D vision).
        </div>
        <div>Contact lens exams include the eyeglasses exams plus determination of curvature of cornea, tear
          film adequacy, contact lens care and contact lens solution suitability, prescribing disposable or
          yearly contact lenses, single vision, astigmatism, bifocal or mono-vision contact lenses.
        </div>
        <div>(Please bring your current eyeglasses and/or contact lens boxes, previous prescription or past
          medical records, list of current medications or bring your medical bottles/boxes. This will provide
          Dr. Hau useful information about your eyes.)
        </div>
        <p>
          <img decoding="async" className="aligncenter wp-image-24 size-large"
               src="assets/uploads/2017/11/eye-looking-left-691269_1920-1024x372.jpg"
               alt="austin-optometrist" width="525" height="191"
               srcSet="assets/uploads/2017/11/eye-looking-left-691269_1920-1024x372.jpg 1024w, assets/uploads/2017/11/eye-looking-left-691269_1920-300x109.jpg 300w, assets/uploads/2017/11/eye-looking-left-691269_1920-768x279.jpg 768w, assets/uploads/2017/11/eye-looking-left-691269_1920.jpg 1920w"
               sizes="(max-width: 525px) 100vw, 525px"/>
        </p>
        <h3>Professional Services fee policies:</h3>
        <div></div>
        <div>We accept payment by cash, check and credit card/debit card, VISA, MasterCard or Discover. Payment
          is due at time of service. We do not sell contact lenses or glasses. We accept some vision plans.
          (Please let us know what vision plan you are using before the exam.)
        </div>
      </div>
    </div>
  );
}

export default Services;
