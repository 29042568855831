function Contact() {
  return (
    <div className="Contact">
      <header className="entry-header">
        <h2 className="entry-title">Contact Your Austin Eye Doctor</h2>
      </header>
      <div className="entry-content">
        <h3>Eye Doctor Hours:</h3>
        <pre>
          <p className="flex">
            <span className="w-80">
              Tuesday, Wednesday, Friday
            </span>
            <span className="aBn">
              10:00 am to 6:30 pm
            </span>
          </p>
          <p className="flex">
            <span className="w-80">
              Saturday
            </span>
            <span className="aBn">
              10:00 am to 5:00 pm
            </span>
          </p>
        </pre>
        <div>
          Walk-in patients are always welcome when appointment times are available. Late/early appointments and other
          days are available upon request.
        </div>
        <div>
          Please call to set up your appointment at <a href="tel:+1512-343-7000">512-343-7000</a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
