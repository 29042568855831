function NotFound() {
  return (
    <div className="NotFound">
      <header className="entry-header">
        <h2 className="entry-title">Oops! That page can&rsquo;t be found.</h2>
      </header>
    </div>
  );
}

export default NotFound;
